:root {
  --surface-d: #dee2e6;
}

#root {
  width: 1100px !important;
}

body, html {
  height:100%;
    padding:0;
    margin:0;
}

.h1-centered {
  font-size: xxx-large;
  text-align: center;
  padding-bottom: 70px;
  padding-top: 70px;
}

.App {
  font-family: 'Lato', sans-serif;
  height: calc(100% - 70px);
  position: fixed;
}

.layout-topbar {
  height: 70px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 997;
  padding: 0 5px;
}

.layout-topbar img {
  float: left;
}

.layout-topbar a {
  padding-right: 10px;
}

.user-details {
  float: right;
  height: 100%;
}

  .user-details span {
    line-height: 70px;
    font: bold;
    font-size: larger;
    padding-right: 20px;
  }

    .user-details i {
      font-size: 1em !important;
      cursor: pointer;
      color: gray;
    }

    .user-details i:hover {
      cursor: pointer;
      color: red;
    }

.layout-sidebar {
  left: 0;
  float: left;
  height: calc(100% - 10px);
  width: 250px;
  overflow: auto;
}

.layout-homepage {
  width: 1100px;
  padding: 0 5px;
  height: calc(100% - 10px);
}

.layout-content {
  width: 850px;
  float: left;
  padding-left: 15px;
  height: calc(100% - 10px);
  overflow: auto;
}
  .layout-content video {
    width: 100%;
  }

.ul-sessions {
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: #fff !important;
}

.ul-sessions li {
  height: 38px;
  padding: 0.7em 1em;
  background-color: rgb(99,118,138);
}

    .ul-sessions li a,
    .ul-sessions li a:hover,
    .ul-sessions li a:visited {
      color: #fff;
      cursor: pointer;
    }

    .ul-sessions li img {
      width: 6%;
      height: auto;
      margin-right: 0.75em;
    }

.feedback,
.resources {
  width: 100%;
  margin-bottom: 1em;
  margin-top: 1em;
}
.feedback textarea {
  width: 100%;
  margin-bottom: 1em;
}

.p-menu {
  width: 100% !important;
}

.p-submenu-header,
.p-menuitem-text {
  color: #fff !important; 
}

.p-submenu-header {
  background-color: rgb(32, 59, 88) !important;
}

.p-menuitem {
  background-color: rgb(99,118,138) !important;
}

  .p-menuitem-link:hover {
    cursor: pointer;
  }

  .p-menuitem-link:hover,
  .p-menuitem-link.active {
    background-color: rgb(129, 143, 117) !important;
  }

.sign-up-form {
  width: 500px;
  margin: 0 auto;
}
  .sign-up-form label {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    display: block;
  }
  .sign-up-form input {
    width: 100%;
    height: 36px;
  }

  .sign-up-form p {
    font-style: italic;
  }

.sign-up-form .buttons {
  margin-top: 1em;
  float: right;
}

.sign-up-form .buttons button {
  margin-left: 1em;
}

.loading-spinner {
  width: 1100px;
}

  .loading-spinner img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  #overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
    text-align: center;
  }

  #overlay .text{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 34px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 30px;
    padding: 15px;
  }

  #overlay .text p{
    margin-top: 5px !important;
  }