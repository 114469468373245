* {
  box-sizing: border-box;
}
#root {
  width: 1200px;
  margin: 0 auto;
}
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Lato', sans-serif;
}